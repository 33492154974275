body {
  margin: 0;
  /* font-family: "Montserrat";
  src: url("../src/fonts/Montserrat-Regular.ttf") format("opentype");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: "Montserrat"; */
}
